import * as React from 'react';
import { render } from 'react-dom';
import { App } from '@common/App';
import * as moment from 'moment';

import './styles/colors.scss';
import './styles/main.scss';

moment.locale('ru');
const root = document.getElementById('root');

let clientEnv: NodeJS.ProcessEnv;

(async () => {
    try {
        clientEnv = await fetch('/client.env').then((res) => res.json());
    } catch (e) {
        console.error(e);
    } finally {
        window.process = window.process || ({} as NodeJS.Process);
        window.process.env = {
            ...window.process.env,
            ...clientEnv,
        };

        const app = <App />;
        render(app, root);
    }
})();
