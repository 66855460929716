import * as lodash from 'lodash';
import { bindActionCreators } from 'redux';
import { BudgetItem, BudgetStatus, CorrectionStatus } from '@mrm/budget';

import { BudgetItemApi, BudgetCorrectionApi } from '@api';

import { store } from '@store';
import { getBudgetByStatusUserConfig } from '@store/userConfig/budget';
import {
    ColumnName,
    UnsavedChange,
    ChangeList,
    REQUIRED_COLUMN_NAMES,
    BUDGET_ITEM_DICTIONARY_COLUMN_NAMES,
    FACT_COLUMN_NAMES,
    RESERVED_COLUMN_NAMES,
} from '@store/budgetExecution/types';
import { getBudgetExecutionPageState, getUnsavedChanges } from '@store/budgetExecution/selectors';
import { setValidationStatus } from '@store/budgetExecution/actions';

import { Utils } from '@common/Utils';

interface StoreProps {
    budgetItems: BudgetItem[];
    unsavedChanges: ChangeList;
    budgetId: string;
}

export class Validator {
    private static instance: Validator;

    private dispatch = bindActionCreators(
        {
            setValidationStatus,
        },
        store.dispatch,
    );

    public static getInstance(): Validator {
        if (!Validator.instance) {
            Validator.instance = new Validator();
        }
        return Validator.instance;
    }

    public checkTableValidation(): boolean {
        const { unsavedChanges } = this.getStoreProps();

        return lodash.every(unsavedChanges, (changes) => this.validateLineChanges(changes));
    }

    public checkLineValidation(lineId: string): boolean {
        const changes = this.getLineChanges(lineId);

        return this.validateLineChanges(changes);
    }

    public updateValidationDisplay() {
        this.dispatch.setValidationStatus(!this.checkTableValidation());
    }

    public async checkTableDataRelevance(): Promise<boolean> {
        const { unsavedChanges } = this.getStoreProps();

        const changedLinesIds = lodash.keys(unsavedChanges).filter((item) => !lodash.isEmpty(unsavedChanges[item]));

        const linesCheckResults = await Promise.all(
            changedLinesIds.map((lineId) => this.checkLineDataRelevance(lineId)),
        );

        return lodash.every(linesCheckResults, (item) => item);
    }

    public async checkLineDataRelevance(lineId: string): Promise<boolean> {
        const actualBudgetItem = await BudgetItemApi.getBudgetItem(lineId);

        const budgetItem = this.getBudgetItemByLineId(lineId);

        const dicts1 =
            actualBudgetItem.dictionary &&
            Object.entries(actualBudgetItem.dictionary).reduce(
                (obj, [key, { status, ...rest }]) => ({ ...obj, [key]: rest }),
                {},
            );
        const dicts2 =
            budgetItem.dictionary &&
            Object.entries(budgetItem.dictionary).reduce(
                (obj, [key, { status, ...rest }]) => ({ ...obj, [key]: rest }),
                {},
            );

        const dictionariesAreActual = lodash.isEqual(dicts1, dicts2);

        return dictionariesAreActual;
    }

    public async checkTableDictionariesRelevance(): Promise<boolean> {
        const { unsavedChanges } = this.getStoreProps();

        const changedLinesIds = lodash.keys(unsavedChanges).filter((item) => !lodash.isEmpty(unsavedChanges[item]));

        const linesCheckResults = await Promise.all(
            changedLinesIds.map((lineId) => this.checkLineDictionariesRelevance(lineId)),
        );

        return lodash.every(linesCheckResults, (item) => item);
    }

    public async checkLineDictionariesRelevance(lineId: string): Promise<boolean> {
        const { unsavedChanges, budgetId } = this.getStoreProps();

        const lineHasDictionaryChanges = unsavedChanges[lineId].some((change) =>
            BUDGET_ITEM_DICTIONARY_COLUMN_NAMES.includes(change.columnName),
        );

        const actualBudgetItemCorrections = await BudgetCorrectionApi.getCorrectionsList({
            budgetId,
            budgetItemId: [lineId],
            filter: {
                status: CorrectionStatus.NeedApproving,
            },
        });

        const lineHasDictionaryCorrections = actualBudgetItemCorrections.some((item) => item.data.params.dictionaryIds);

        return !(lineHasDictionaryChanges && lineHasDictionaryCorrections);
    }

    private validateLineChanges(changes: UnsavedChange[]): boolean {
        const requiredFieldsChanges = this.filterChangesByColumnName(changes, REQUIRED_COLUMN_NAMES);
        const requiredFieldsHaveValues = requiredFieldsChanges.every((change) => !!change.value);

        let activityNameIsValid = true;
        let sapCommentIsValid = true;
        let formulaFieldsAreValid = true;

        const activityNameChange = changes.find((item) => item.columnName == ColumnName.ActivityName);
        const sapCommentChange = changes.find((item) => item.columnName == ColumnName.SapComment);
        const formulaFieldChanges = changes.filter(
            (item) => FACT_COLUMN_NAMES.includes(item.columnName) || RESERVED_COLUMN_NAMES.includes(item.columnName),
        );

        if (activityNameChange) {
            const activityName = activityNameChange.value as string;

            activityNameIsValid = activityName && activityName.length >= 3;
        }

        if (sapCommentChange) {
            const sapComment = sapCommentChange.value as string;

            sapCommentIsValid = sapComment && sapComment.length >= 3;
        }

        if (formulaFieldChanges?.length) {
            formulaFieldsAreValid = formulaFieldChanges.every((change) =>
                change.value ? Utils.calculateCurrencyFormula(change.value as string) >= 0 : true,
            );
        }

        return requiredFieldsHaveValues && activityNameIsValid && sapCommentIsValid && formulaFieldsAreValid;
    }

    private filterChangesByColumnName(changes: UnsavedChange[], columnNames: ColumnName[]): UnsavedChange[] {
        return changes.filter((item) => lodash.includes(columnNames, item.columnName));
    }

    private getBudgetItemByLineId(lineId: string): BudgetItem {
        const { budgetItems } = this.getStoreProps();

        return budgetItems.find((budgetItem) => budgetItem.id === lineId);
    }

    private getLineChanges(lineId: string): UnsavedChange[] {
        const { unsavedChanges } = this.getStoreProps();

        return unsavedChanges[lineId];
    }

    private getStoreProps(): StoreProps {
        const storeState = store.getState();

        const { pageBudgetItems: budgetItems, lineIdsWithActualChanges } =
            getBudgetExecutionPageState(storeState).computedData;

        const { budgetId } = getBudgetByStatusUserConfig(storeState, BudgetStatus.Execution);

        const unsavedChanges = getUnsavedChanges(storeState);
        const actualUnsavedChanges = lineIdsWithActualChanges.reduce(
            (acc, lineId) => ({
                ...acc,
                [lineId]: unsavedChanges[lineId],
            }),
            {},
        );

        return {
            budgetItems,
            unsavedChanges: actualUnsavedChanges,
            budgetId,
        };
    }
}
